import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { auth } from "../../utils/Authentication";
import { publicIpv4 } from "public-ip";
import SignInPopup from "../Core/SignInPopup";
import { t } from "i18next";
import {
  getProducts,
  createCheckoutSession,
} from "../../utils/main-functionality";
import { useRouter } from "next/router";
import { formatAmountForDisplay } from "../../utils/stripe-helpers";
import { motion } from "framer-motion";

export default function PriceTable() {
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("101.167.184.0");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const router = useRouter();
  const [currency, setCurrency] = useState<string>("GBP");

  const generateFormula = async (priceId: string, type: string) => {
    if (!auth.currentUser) {
      createCheckoutSession(priceId, "", type).then((session) => {
        if (session) {
          router.push(session.url as string);
        } else {
          toast.error(t("toaster.error-occurred"));
        }
      });
      return;
    }

    createCheckoutSession(priceId, auth.currentUser?.email ?? "", type).then(
      (session) => {
        if (session) {
          router.push(session.url as string);
        } else {
          toast.error(t("toaster.error-occurred"));
        }
      }
    );
  };

  const isUnlimitedUser = async () => {
    await publicIpv4().then((ip) => setIpAddress(ip));
    if (auth.currentUser?.email) {
      const response = await fetch("/api/user/type", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: auth.currentUser,
        }),
      });

      const { isUnlimitedUser } = await response.json();

      setUnlimitedUser(isUnlimitedUser);
    }
  };

  const getCurrency = async () => {
    await publicIpv4()
      .then((ip) => setIpAddress(ip))
      .catch((err) => setIpAddress("101.167.184.0"));
    const response = await fetch("/api/get-currency", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ipAddress: ipAddress,
      }),
    });

    const { currency } = await response.json();
    setCurrency(currency);
  };

  useEffect(() => {
    const fetchData = async () => {
      await isUnlimitedUser();
      await getCurrency().then(() => {
        getProducts(currency).then((products) => {
          const professionalizeItToMePrices = products.filter(
            (product: any) =>
              product.name.includes("Membership") ||
              product.name.includes("Unlimited")
          );

          professionalizeItToMePrices.sort((a: any, b: any) => {
            return (
              (a.prices[0]?.unit_amount || 0) - (b.prices[0]?.unit_amount || 0)
            );
          });
          setProducts(professionalizeItToMePrices);
        });
      });
    };
    fetchData();
  }, []);
  return (
    <>
      <SignInPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj"
            >
              {t("product.choose-your-plan")}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mt-5 text-lg font-normal text-gray-900 font-pj"
            >
              {t("product.choose-your-plan-description")}
            </motion.p>
          </div>
          {products.length > 0 ? (
            <div className="grid max-w-screen-xl grid-cols-1 mx-auto mt-8 text-center sm:grid-cols-3 gap-y-2 gap-x-5">
              {products.map((product: any, index: number) => (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className={`relative mt-12  ${
                    (product.name.includes("Lifetime") ||
                      product.name.includes("Yearly")) &&
                    "col-span-[1_/_span_2]"
                  }`}
                  key={product.id}
                >
                  {(product.name.includes("Monthly") ||
                    product.name.includes("Yearly")) && (
                    <div className="absolute -inset-2">
                      <div
                        className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
                        style={{
                          background:
                            "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                    <div className="p-6 md:p-10">
                      <p className="text-lg font-bold text-gray-900 font-pj">
                        {t(`product.${product.id}.title`)}
                      </p>
                      <ul className="px-6 mx-auto list-disc">
                        <li className="mt-4 text-left text-sm font-normal leading-7 text-gray-600 font-pj">
                          {t(`product.${product.id}.description`)}
                        </li>
                        <li className="mt-0 text-left text-sm font-normal leading-7 text-gray-600 font-pj">
                          {t(`product.ads-free`)}
                        </li>
                        <li className="mt-0 text-left text-sm font-normal leading-7 text-gray-600 font-pj">
                          {t(`product.use-gpt4`)}
                        </li>
                      </ul>
                      <p className="mb-4 mt-4 text-xxs font-pj bg-gray-700 uppercase rounded-md w-28 text-white mx-auto py-1 font-inter">
                        {product.prices[0].type === "recurring"
                          ? t("product.subscription")
                          : t("product.one-time")}
                      </p>
                      <p className="text-xl font-bold text-gray-900 font-pj">
                        {formatAmountForDisplay(
                          product.prices[0].currency_options[
                            currency.toLowerCase()
                          ].unit_amount / 100,
                          currency
                        )}
                      </p>
                      <button
                        onClick={() => {
                          generateFormula(
                            product.prices[0].id,
                            product.prices[0].type
                          );
                        }}
                        title=""
                        className="
                                            inline-flex
                                            items-center
                                            justify-center
                                            px-12
                                            py-2
                                            mt-6
                                            text-base
                                            font-bold
                                            text-gray-100
                                            transition-all
                                            duration-200
                                            border-2 border-gray-400
                                            rounded-xl
                                            font-pj
                                            bg-gray-800
                                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                                            hover:bg-gray-900
                                            focus:bg-gray-900
                                            hover:text-white
                                            focus:text-white
                                            hover:border-gray-900
                                            focus:border-gray-900
                                        "
                        role="button"
                      >
                        {t("product.buy-now")}
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          ) : (
            <div className="grid max-w-screen-xl grid-cols-1 mx-auto mt-8 text-center sm:grid-cols-3 gap-y-2 gap-x-5">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="relative mt-12"
              >
                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 w-80 md:p-10">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="relative mt-12"
              >
                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 md:p-10">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="relative mt-12"
              >
                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 md:p-10">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="relative mt-12"
              >
                <div className="absolute -inset-2">
                  <div
                    className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
                    style={{
                      background:
                        "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                    }}
                  ></div>
                </div>
                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 md:p-10">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="relative mt-12"
              >
                <div className="absolute -inset-2">
                  <div
                    className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
                    style={{
                      background:
                        "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                    }}
                  ></div>
                </div>
                <div className="relative overflow-hidden bg-white border border-gray-200 rounded-2xl">
                  <div className="p-6 md:p-10">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mt-4 mx-auto"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mt-4 mx-auto"></div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}

          <p className="max-w-lg mx-auto mt-20 text-sm font-pj font-normal leading-7 text-center text-gray-600 font-pj">
            {t("product.30-days-refund")}
          </p>
        </div>
      </section>
    </>
  );
}
